import { createSlice } from "@reduxjs/toolkit";

const globalSlice = createSlice({
  name: "global",
  initialState: {
    loading: false,
    toastMessage: null,
    toastType: 0,
  },
  reducers: {
    updateLoading: (state, action) => {
      state.loading = action.payload;
    },
    updateToast: (state, action) => {
      state.toastMessage = action.payload.message;
      state.toastType = action.payload.type;
    },
  },
});

export const { updateLoading, updateToast } = globalSlice.actions;
export default globalSlice.reducer;
