import { createApi } from "@reduxjs/toolkit/query/react";
import { updateToast } from "../redux/global-slice";
import { updateUser } from "../redux/auth-slice";
import { baseQueryWithAuth } from "../utils/utils";

export const authService = createApi({
  reducerPath: "authService",
  baseQuery: baseQueryWithAuth("auth"),
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: (body) => ({ url: "/login", method: "POST", body }),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          var type = 0;
          var message = "Something went wrong!";
          if (data) {
            if (["student", "teacher"].includes(data.role)) {
              type = 0;
              message = "User not authorized to login!";
            } else {
              type = 1;
              message = "Logged in successfully!";
              dispatch(updateUser(data));
            }
          }
          dispatch(updateToast({ type: type, message: message }));
        } catch (_) {}
      },
    }),
    newUser: builder.mutation({
      query: (body) => ({ url: "/register", method: "POST", body }),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            updateToast({ type: 1, message: "User created successfully!" })
          );
        } catch (_) {}
      },
    }),
  }),
});

export const { useLoginUserMutation, useNewUserMutation } = authService;
