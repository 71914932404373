import {
  ChevronUpSquare,
  CornerUpLeftIcon,
  LayoutDashboardIcon,
  UniversityIcon,
  Users,
} from "lucide-react";
import {
  COURSE_TYPE_PATH,
  COURSES_PATH,
  OFFERS_PATH,
  SCHOOLS_PATH,
  USERS_PATH,
} from "./route-contants";
import { listsService } from "../services/lists-service";
import { addressService } from "../services/address-service";
import { gaurdianService } from "../services/gaurdian-service";
import { authService } from "../services/auth-service";
import { userService } from "../services/user-service";

export const sideMenuItems = [
  { icon: <LayoutDashboardIcon />, title: "Dashboard", route: "" },
  { icon: <Users />, title: "Users", route: USERS_PATH },
  { icon: <UniversityIcon />, title: "Schools", route: SCHOOLS_PATH },
  { icon: <CornerUpLeftIcon />, title: "Course Type", route: COURSE_TYPE_PATH },
  { icon: <ChevronUpSquare />, title: "Courses", route: COURSES_PATH },
  { icon: <CornerUpLeftIcon />, title: "Offers", route: OFFERS_PATH },
];

export const apiServicesList = [
  listsService,
  addressService,
  gaurdianService,
  authService,
  userService,
];

export const getGenderList = [
  { key: "male", title: "Male" },
  { key: "female", title: "Female" },
  { key: "other", title: "Other" },
];

export const getRolesList = (role) => {
  var roles = [
    { key: "student", title: "Student" },
    { key: "teacher", title: "Teacher" },
  ];
  if (role === "super_admin" || role === "admin") {
    if (role === "super_admin") {
      roles.push({ key: "admin", title: "Admin" });
    }
    roles.push({ key: "accountant", title: "Accountant" });
  }
  return roles;
  // return [
  //   { key: "super_admin", title: "Super Admin" },
  //   { key: "admin", title: "Admin" },
  //   { key: "accountant", title: "Accountant" },
  //   { key: "student", title: "Student" },
  //   { key: "teacher", title: "Teacher" },
  // ];
};

export const relationList = [
  { key: "grand_father", title: "Grand Father" },
  { key: "grand_mother", title: "Grand Mother" },
  { key: "father", title: "Father" },
  { key: "mother", title: "Mother" },
  { key: "brother", title: "Brother" },
  { key: "sister", title: "Sister" },
  { key: "uncle", title: "Uncle" },
  { key: "aunt", title: "Aunt" },
  { key: "other", title: "Other" },
];
