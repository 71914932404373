import { useSelector } from "react-redux";
import { userReducer } from "../redux/reducers";

const useUserInfo = () => {
  const user = useSelector(userReducer);
  const {
    about,
    createdAt,
    created_by,
    date_of_birth,
    delete_status,
    email,
    first_name,
    gender,
    id: user_id,
    is_active,
    is_verified,
    last_name,
    middle_name,
    phone_number,
    photo,
    role,
    updatedAt,
    updatedBy,
    username,
  } = user;
  const fullname = `${first_name} ${middle_name} ${last_name}`;
  return {
    about,
    createdAt,
    created_by,
    date_of_birth,
    delete_status,
    email,
    first_name,
    gender,
    user_id,
    is_active,
    is_verified,
    middle_name,
    last_name,
    phone_number,
    photo,
    role,
    updatedAt,
    updatedBy,
    username,
    fullname,
  };
};

export default useUserInfo;
