import "../css/_toast.scss";
import { useDispatch, useSelector } from "react-redux";
import { updateToast } from "../redux/global-slice";
import { toastMessageReducer, toastTypeReducer } from "../redux/reducers";
import { CheckCircle2, Info, XCircle } from "lucide-react";
import { useEffect, useState } from "react";

// info = 0, success = 1, error = 2
const Toast = () => {
  const dispatch = useDispatch();
  const [toast, setToast] = useState(false);
  const message = useSelector(toastMessageReducer);
  const type = useSelector(toastTypeReducer);

  useEffect(() => {
    if (message) {
      setToast(true);
      setTimeout(() => {
        dispatch(updateToast({ message: null, type: 0 }));
        setToast(false);
      }, 5000);
    } else {
      setToast(false);
    }
  }, [message]);

  return (
    toast && (
      <div className="custom__toast__container">
        <div className="custom__toast flex align-center gap2 radius1 px4 py3 mx4">
          <div>
            {type === 0 && <Info size={16} color="grey" />}
            {type === 1 && <CheckCircle2 size={16} color="green" />}
            {type === 2 && <XCircle size={16} color="red" />}
          </div>
          <p>{message}</p>
          <button
            className="custom__toast__close"
            onClick={() => dispatch(updateToast({ message: null, type: 0 }))}
          >
            &times;
          </button>
        </div>
      </div>
    )
  );
};

export default Toast;
