import { useState } from "react";
import TableList from "../components/table_list";
import {
  useDeleteUserMutation,
  useGetAllUsersQuery,
} from "../services/user-service";
import Tag from "../components/tag";
import Dialog from "../components/dialog";
import Tabbar from "../components/tabbar";
import BasicInfo from "../components/info/basic-info";
import AddressInfo from "../components/info/address-info";
import GaurdianInfo from "../components/info/gaurdian-info";
import Popover from "../components/popover";

const _headers = [
  { key: "name", title: "Full Name" },
  { key: "phone_number", title: "Phone" },
  { key: "email", title: "Email" },
  { key: "username", title: "Username" },
  { key: "role", title: "Role" },
  { key: "action", title: "Action" },
];

const Users = () => {
  const [deleteUser] = useDeleteUserMutation();
  const { isLoading, data, error, isFetching } = useGetAllUsersQuery();
  const [dialog, setDailog] = useState(false);
  const [selected, setSelected] = useState(null);
  const [view, setView] = useState(true);

  const reset = () => {
    setDailog(false);
    setSelected(null);
    setView(true);
  };

  return (
    <>
      <TableList
        headers={_headers}
        loading={isLoading || isFetching}
        error={error}
        data={(data ?? []).map((item) => ({
          ...item,
          name: `${item.basic_info.first_name} ${item.basic_info.middle_name} ${item.basic_info.last_name}`,
          gender: <Tag text={item.basic_info.gender} />,
          role: <Tag text={item.basic_info.role} />,
          phone_number: item.basic_info.phone_number,
          email: item.basic_info.email,
          username: item.basic_info.username,
          action: (
            <Popover
              view={() => {
                setSelected(item);
                setDailog(true);
                setView(true);
              }}
              edit={() => {
                setSelected(item);
                setDailog(true);
                setView(false);
              }}
              deleteFun={() => {
                deleteUser(item.id);
              }}
            />
          ),
        }))}
        onClick={() => {
          setSelected(null);
          setDailog(true);
          setView(false);
        }}
      />
      <Dialog open={dialog} setOpen={setDailog}>
        <Tabbar
          tabs={[
            {
              label: "Basic Info",
              content: (
                <BasicInfo
                  view={view}
                  reset={reset}
                  selected={selected?.basic_info}
                  setSelected={setSelected}
                />
              ),
            },
            {
              label: "Temporary Address",
              content: (
                <AddressInfo view={view} type="temporary" reset={reset} />
              ),
            },
            {
              label: "Permanent Address",
              content: (
                <AddressInfo view={view} type="permanent" reset={reset} />
              ),
            },
            {
              label: "Guardian Info",
              content: <GaurdianInfo view={view} title={null} reset={reset} />,
            },
          ]}
        />
      </Dialog>
    </>
  );
};

export default Users;
