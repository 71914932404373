import { Bell, User } from "lucide-react";

const Header = () => {
  return (
    <header className="flex align-center justify-between px4">
      <div style={{ textTransform: "uppercase", fontWeight: "bold" }}>
        study2ool
      </div>
      <div className="icon__menus flex align-center gap2">
        <button className="icon">
          <Bell size={16} />
        </button>
        <button className="icon">
          <User size={16} />
        </button>
      </div>
    </header>
  );
};

export default Header;
