export const allProvidencesList = [
  { id: 1, name: "Koshi" },
  { id: 2, name: "Madhesh" },
  { id: 3, name: "Bagmati" },
  { id: 4, name: "Gandaki" },
  { id: 5, name: "Lumbini" },
  { id: 6, name: "Karnali" },
  { id: 7, name: "Sudurpaschim" },
];

export const allDistrictsList = [
  { id: 1, province_id: 1, name: "Bhojpur" },
  { id: 2, province_id: 1, name: "Dhankuta" },
  { id: 3, province_id: 1, name: "Ilam" },
  { id: 4, province_id: 1, name: "Jhapa" },
  { id: 5, province_id: 1, name: "Khotang" },
  { id: 6, province_id: 1, name: "Morang" },
  { id: 7, province_id: 1, name: "Okhaldhunga" },
  { id: 8, province_id: 1, name: "Pachthar" },
  { id: 9, province_id: 1, name: "Sankhuwasabha" },
  { id: 10, province_id: 1, name: "Solukhumbu" },
  { id: 11, province_id: 1, name: "Sunsari" },
  { id: 12, province_id: 1, name: "Taplejung" },
  { id: 13, province_id: 1, name: "Terhathum" },
  { id: 14, province_id: 1, name: "Udayapur" },
  { id: 15, province_id: 2, name: "Parsa" },
  { id: 16, province_id: 2, name: "Bara" },
  { id: 17, province_id: 2, name: "Rautahat" },
  { id: 18, province_id: 2, name: "Sarlahi" },
  { id: 19, province_id: 2, name: "Siraha" },
  { id: 20, province_id: 2, name: "Dhanusha" },
  { id: 21, province_id: 2, name: "Saptari" },
  { id: 22, province_id: 2, name: "Mahottari" },
  { id: 23, province_id: 3, name: "Bhaktapur" },
  { id: 24, province_id: 3, name: "Chitwan" },
  { id: 25, province_id: 3, name: "Dhading" },
  { id: 26, province_id: 3, name: "Dolakha" },
  { id: 27, province_id: 3, name: "Kathmandu" },
  { id: 28, province_id: 3, name: "Kavrepalanchok" },
  { id: 29, province_id: 3, name: "Lalitpur" },
  { id: 30, province_id: 3, name: "Makwanpur" },
  { id: 31, province_id: 3, name: "Nuwakot" },
  { id: 32, province_id: 3, name: "Ramechap" },
  { id: 33, province_id: 3, name: "Rasuwa" },
  { id: 34, province_id: 3, name: "Sindhuli" },
  { id: 35, province_id: 3, name: "Sindhupalchok" },
  { id: 36, province_id: 4, name: "Baglung" },
  { id: 37, province_id: 4, name: "Gorkha" },
  { id: 38, province_id: 4, name: "Kaski" },
  { id: 39, province_id: 4, name: "Lamjung" },
  { id: 40, province_id: 4, name: "Manang" },
  { id: 41, province_id: 4, name: "Mustang" },
  { id: 42, province_id: 4, name: "Myagdi" },
  { id: 43, province_id: 4, name: "Nawalpur" },
  { id: 44, province_id: 4, name: "Parwat" },
  { id: 45, province_id: 4, name: "Syangja" },
  { id: 46, province_id: 4, name: "Tanahun" },
  { id: 47, province_id: 5, name: "Kapilvastu" },
  { id: 48, province_id: 5, name: "Parasi" },
  { id: 49, province_id: 5, name: "Rupandehi" },
  { id: 50, province_id: 5, name: "Arghakhanchi" },
  { id: 51, province_id: 5, name: "Gulmi" },
  { id: 52, province_id: 5, name: "Palpa" },
  { id: 53, province_id: 5, name: "Dang" },
  { id: 54, province_id: 5, name: "Pyuthan" },
  { id: 55, province_id: 5, name: "Rolpa" },
  { id: 56, province_id: 5, name: "Eastern Rukum" },
  { id: 57, province_id: 5, name: "Banke" },
  { id: 58, province_id: 5, name: "Bardiya" },
  { id: 59, province_id: 6, name: "Western Rukum" },
  { id: 60, province_id: 6, name: "Salyan" },
  { id: 61, province_id: 6, name: "Dolpa" },
  { id: 62, province_id: 6, name: "Humla" },
  { id: 63, province_id: 6, name: "Jumla" },
  { id: 64, province_id: 6, name: "Kalikot" },
  { id: 65, province_id: 6, name: "Mugu" },
  { id: 66, province_id: 6, name: "Surkhet" },
  { id: 67, province_id: 6, name: "Dailekh" },
  { id: 68, province_id: 6, name: "Jajarkot" },
  { id: 69, province_id: 7, name: "Darchula" },
  { id: 70, province_id: 7, name: "Bajhang" },
  { id: 71, province_id: 7, name: "Bajura" },
  { id: 72, province_id: 7, name: "Baitadi" },
  { id: 73, province_id: 7, name: "Doti" },
  { id: 74, province_id: 7, name: "Acham" },
  { id: 75, province_id: 7, name: "Dadeldhura" },
  { id: 76, province_id: 7, name: "Kanchanpur" },
  { id: 77, province_id: 7, name: "Kailali" },
];

export const allMuncipalsList = [
  { id: 1, district_id: 1, name: "Shadanand" },
  { id: 2, district_id: 1, name: "Bhojpur" },
  { id: 3, district_id: 1, name: "Hatuwagadhi" },
  { id: 4, district_id: 1, name: "Ramprasad Rai" },
  { id: 5, district_id: 1, name: "Aamchok" },
  { id: 6, district_id: 1, name: "Tyamke Maiyum" },
  { id: 7, district_id: 1, name: "Arun" },
  { id: 8, district_id: 1, name: "Pauwadungma" },
  { id: 9, district_id: 1, name: "Salpasilichho" },
  { id: 10, district_id: 2, name: "Dhankuta" },
  { id: 11, district_id: 2, name: "Pakhribas" },
  { id: 12, district_id: 2, name: "Mahalaxmi" },
  { id: 13, district_id: 2, name: "Sangurigadhi" },
  { id: 14, district_id: 2, name: "Chaubise" },
  { id: 15, district_id: 2, name: "Sahidbhumi" },
  { id: 16, district_id: 2, name: "Chhathar Jorpati" },
  { id: 17, district_id: 3, name: "Suryodaya" },
  { id: 18, district_id: 3, name: "Ilam" },
  { id: 19, district_id: 3, name: "Deumai" },
  { id: 20, district_id: 3, name: "Maijogmai" },
  { id: 21, district_id: 3, name: "Phakphokthum" },
  { id: 22, district_id: 3, name: "Mai" },
  { id: 23, district_id: 3, name: "Chulachuli" },
  { id: 24, district_id: 3, name: "Rong" },
  { id: 25, district_id: 3, name: "Mangsebung" },
  { id: 26, district_id: 3, name: "Sandakpur" },
  { id: 27, district_id: 4, name: "Mechinagar" },
  { id: 28, district_id: 4, name: "Birtamod" },
  { id: 29, district_id: 4, name: "Damak" },
  { id: 30, district_id: 4, name: "Bhadrapur" },
  { id: 31, district_id: 4, name: "Shivasatakshi" },
  { id: 32, district_id: 4, name: "Arjundhara" },
  { id: 33, district_id: 4, name: "Gauradaha" },
  { id: 34, district_id: 4, name: "Kankai" },
  { id: 35, district_id: 4, name: "Kamal" },
  { id: 36, district_id: 4, name: "Buddha Shanti" },
  { id: 37, district_id: 4, name: "Kachankawal" },
  { id: 38, district_id: 4, name: "Jhapa" },
  { id: 39, district_id: 4, name: "Barhadashi" },
  { id: 40, district_id: 4, name: "Gaurigunj" },
  { id: 41, district_id: 4, name: "Haldibari" },
  { id: 42, district_id: 5, name: "Diktel Rupakot Majhuwagadhi" },
  { id: 43, district_id: 5, name: "Halesi Tuwachung" },
  { id: 44, district_id: 5, name: "Khotehang" },
  { id: 45, district_id: 5, name: "Diprung Chuichumma" },
  { id: 46, district_id: 5, name: "Aiselukharka" },
  { id: 47, district_id: 5, name: "Jantedhunga" },
  { id: 48, district_id: 5, name: "Kepilasgadhi" },
  { id: 49, district_id: 5, name: "Barahpokhari" },
  { id: 50, district_id: 5, name: "Rawa Besi" },
  { id: 51, district_id: 5, name: "Sakela" },
  { id: 52, district_id: 6, name: "Sundar Haraicha" },
  { id: 53, district_id: 6, name: "Belbari" },
  { id: 54, district_id: 6, name: "Pathari Shanischare" },
  { id: 55, district_id: 6, name: "Ratuwamai" },
  { id: 56, district_id: 6, name: "Urlabari" },
  { id: 57, district_id: 6, name: "Rangeli" },
  { id: 58, district_id: 6, name: "Sunawarshi" },
  { id: 59, district_id: 6, name: "Letang" },
  { id: 60, district_id: 6, name: "Biratnagar" },
  { id: 61, district_id: 6, name: "Jahada" },
  { id: 62, district_id: 6, name: "Budi Ganga" },
  { id: 63, district_id: 6, name: "Katahari" },
  { id: 64, district_id: 6, name: "Dhanpalthan" },
  { id: 65, district_id: 6, name: "Kanepokhari" },
  { id: 66, district_id: 6, name: "Gramthan" },
  { id: 67, district_id: 6, name: "Kerabari" },
  { id: 68, district_id: 6, name: "Miklajung" },
  { id: 69, district_id: 7, name: "Siddhicharan" },
  { id: 70, district_id: 7, name: "Khiji Demba" },
  { id: 71, district_id: 7, name: "Chisankhugadhi" },
  { id: 72, district_id: 7, name: "Molung" },
  { id: 73, district_id: 7, name: "Sunkoshi" },
  { id: 74, district_id: 7, name: "Champadevi" },
  { id: 75, district_id: 7, name: "Manebhanjyang" },
  { id: 76, district_id: 7, name: "Likhu" },
  { id: 77, district_id: 8, name: "Phidim" },
  { id: 78, district_id: 8, name: "Miklajung" },
  { id: 79, district_id: 8, name: "Phalgunanda" },
  { id: 80, district_id: 8, name: "Hilihang" },
  { id: 81, district_id: 8, name: "Phalelung" },
  { id: 82, district_id: 8, name: "Yangwarak" },
  { id: 83, district_id: 8, name: "Kummayak" },
  { id: 84, district_id: 8, name: "Tumbewa" },
  { id: 85, district_id: 9, name: "Khandbari" },
  { id: 86, district_id: 9, name: "Chainpur" },
  { id: 87, district_id: 9, name: "Dharmadevi" },
  { id: 88, district_id: 9, name: "Panchkhapan" },
  { id: 89, district_id: 9, name: "Madi" },
  { id: 90, district_id: 9, name: "Makalu" },
  { id: 91, district_id: 9, name: "Silichong" },
  { id: 92, district_id: 9, name: "Sabhapokhari" },
  { id: 93, district_id: 9, name: "Chichila" },
  { id: 94, district_id: 9, name: "BhotKhola" },
  { id: 95, district_id: 10, name: "Solu Dudhkunda" },
  { id: 96, district_id: 10, name: "Mapya Dudhkoshi" },
  { id: 97, district_id: 10, name: "Necha Salyan" },
  { id: 98, district_id: 10, name: "Thulung Dudhkoshi" },
  { id: 99, district_id: 10, name: "Maha Kulung" },
  { id: 100, district_id: 10, name: "Sotang" },
  { id: 101, district_id: 10, name: "Khumbu PasangLhamu" },
  { id: 102, district_id: 10, name: "Likhu Pike" },
  { id: 103, district_id: 11, name: "BarahaKshetra" },
  { id: 104, district_id: 11, name: "Inaruwa" },
  { id: 105, district_id: 11, name: "Duhabi" },
  { id: 106, district_id: 11, name: "Ramdhuni" },
  { id: 107, district_id: 11, name: "Itahari" },
  { id: 108, district_id: 11, name: "Dharan" },
  { id: 109, district_id: 11, name: "Koshi" },
  { id: 110, district_id: 11, name: "Harinagar" },
  { id: 111, district_id: 11, name: "Bhokraha Narsingh" },
  { id: 112, district_id: 11, name: "Dewangunj" },
  { id: 113, district_id: 11, name: "Gadhi" },
  { id: 114, district_id: 11, name: "Barju" },
  { id: 115, district_id: 12, name: "Phungling" },
  { id: 116, district_id: 12, name: "Sirijangha" },
  { id: 117, district_id: 12, name: "Aathrai Triveni" },
  { id: 118, district_id: 12, name: "Pathibhara Yangwarak" },
  { id: 119, district_id: 12, name: "Meringden" },
  { id: 120, district_id: 12, name: "Sidingwa" },
  { id: 121, district_id: 12, name: "Phaktanglung" },
  { id: 122, district_id: 12, name: "Maiwa Khola" },
  { id: 123, district_id: 12, name: "Mikwa Khola" },
  { id: 124, district_id: 13, name: "Myanglung" },
  { id: 125, district_id: 13, name: "Laligurans" },
  { id: 126, district_id: 13, name: "Aathrai" },
  { id: 127, district_id: 13, name: "Phedap" },
  { id: 128, district_id: 13, name: "Chhathar" },
  { id: 129, district_id: 13, name: "Menchayayem" },
  { id: 130, district_id: 14, name: "Triyuga" },
  { id: 131, district_id: 14, name: "Katari" },
  { id: 132, district_id: 14, name: "Chaudandigadhi" },
  { id: 133, district_id: 14, name: "Belaka" },
  { id: 134, district_id: 14, name: "Udayapurgadhi" },
  { id: 135, district_id: 14, name: "Rautamai" },
  { id: 136, district_id: 14, name: "Tapli" },
  { id: 137, district_id: 14, name: "Limchungbung" },
  { id: 138, district_id: 15, name: "Birgunj" },
  { id: 139, district_id: 15, name: "Bahudarmai" },
  { id: 140, district_id: 15, name: "Parsagadhi" },
  { id: 141, district_id: 15, name: "Pokhariya" },
  { id: 142, district_id: 15, name: "Bindabasini" },
  { id: 143, district_id: 15, name: "Dhobini" },
  { id: 144, district_id: 15, name: "Chhipaharmai" },
  { id: 145, district_id: 15, name: "Jagarnathpur" },
  { id: 146, district_id: 15, name: "Jirabhawani" },
  { id: 147, district_id: 15, name: "Kalikamai" },
  { id: 148, district_id: 15, name: "Pakaha Mainpur" },
  { id: 149, district_id: 15, name: "Paterwa Sugauli" },
  { id: 150, district_id: 15, name: "Sakhuwa Prasauni" },
  { id: 151, district_id: 15, name: "Thori" },
  { id: 152, district_id: 16, name: "Kalaiya" },
  { id: 153, district_id: 16, name: "Jitpur Simara" },
  { id: 154, district_id: 16, name: "Kolhabi" },
  { id: 155, district_id: 16, name: "Nijgadh" },
  { id: 156, district_id: 16, name: "Mahagadhimai" },
  { id: 157, district_id: 16, name: "Simaraungadh" },
  { id: 158, district_id: 16, name: "Pacharauta" },
  { id: 159, district_id: 16, name: "Pheta" },
  { id: 160, district_id: 16, name: "Bishrampur" },
  { id: 161, district_id: 16, name: "Prasauni" },
  { id: 162, district_id: 16, name: "Adarsh Kotwal" },
  { id: 163, district_id: 16, name: "Karaiyamai" },
  { id: 164, district_id: 16, name: "Devtal" },
  { id: 165, district_id: 16, name: "Parwanipur" },
  { id: 166, district_id: 16, name: "Baragadhi" },
  { id: 167, district_id: 16, name: "Suwarna" },
  { id: 168, district_id: 17, name: "Baudhimai" },
  { id: 169, district_id: 17, name: "Brindaban" },
  { id: 170, district_id: 17, name: "Chandrapur" },
  { id: 171, district_id: 17, name: "Dewahi Gonahi" },
  { id: 172, district_id: 17, name: "Gadhimai" },
  { id: 173, district_id: 17, name: "Guruda" },
  { id: 174, district_id: 17, name: "Gaur" },
  { id: 175, district_id: 17, name: "Gujara" },
  { id: 176, district_id: 17, name: "Ishanath" },
  { id: 177, district_id: 17, name: "Katahariya" },
  { id: 178, district_id: 17, name: "Madhav Narayan" },
  { id: 179, district_id: 17, name: "Maulapur" },
  { id: 180, district_id: 17, name: "Paroha" },
  { id: 181, district_id: 17, name: "Phatuwa Bijayapur" },
  { id: 182, district_id: 17, name: "Rajdevi" },
  { id: 183, district_id: 17, name: "Rajpur" },
  { id: 184, district_id: 17, name: "Durga Bhagwati" },
  { id: 185, district_id: 17, name: "Yamunamai" },
  { id: 186, district_id: 18, name: "Bagmati" },
  { id: 187, district_id: 18, name: "Balara" },
  { id: 188, district_id: 18, name: "Barahathwa" },
  { id: 189, district_id: 18, name: "Godaita" },
  { id: 190, district_id: 18, name: "Hariwan" },
  { id: 191, district_id: 18, name: "Haripur" },
  { id: 192, district_id: 18, name: "Haripurwa" },
  { id: 193, district_id: 18, name: "Ishowrpur" },
  { id: 194, district_id: 18, name: "Kabilasi" },
  { id: 195, district_id: 18, name: "Lalbandi" },
  { id: 196, district_id: 18, name: "Malangawa" },
  { id: 197, district_id: 18, name: "Basbariya" },
  { id: 198, district_id: 18, name: "Bisnu" },
  { id: 199, district_id: 18, name: "Brahampuri" },
  { id: 200, district_id: 18, name: "Chakraghatta" },
  { id: 201, district_id: 18, name: "Chandranagar" },
  { id: 202, district_id: 18, name: "Dhankaul" },
  { id: 203, district_id: 18, name: "Kaudena" },
  { id: 204, district_id: 18, name: "Parsa" },
  { id: 205, district_id: 18, name: "Ramnagar" },
  { id: 206, district_id: 19, name: "Lahan" },
  { id: 207, district_id: 19, name: "Dhangadhimai" },
  { id: 208, district_id: 19, name: "Siraha" },
  { id: 209, district_id: 19, name: "Golbazar" },
  { id: 210, district_id: 19, name: "Mirchaiya" },
  { id: 211, district_id: 19, name: "Kalyanpur" },
  { id: 212, district_id: 19, name: "Karjanha" },
  { id: 213, district_id: 19, name: "Sukhipur" },
  { id: 214, district_id: 19, name: "Bhagwanpur" },
  { id: 215, district_id: 19, name: "Aurahi" },
  { id: 216, district_id: 19, name: "Bishnupur" },
  { id: 217, district_id: 19, name: "Bariyarpatti" },
  { id: 218, district_id: 19, name: "Lakshmipur Patari" },
  { id: 219, district_id: 19, name: "Naraha" },
  { id: 220, district_id: 19, name: "SakhuwanankarKatti" },
  { id: 221, district_id: 19, name: "Arnama" },
  { id: 222, district_id: 19, name: "Navarajpur" },
  { id: 223, district_id: 20, name: "Janakpurdham" },
  { id: 224, district_id: 20, name: "Chhireshwarnath" },
  { id: 225, district_id: 20, name: "Ganeshman Charnath" },
  { id: 226, district_id: 20, name: "Dhanushadham" },
  { id: 227, district_id: 20, name: "Nagarain" },
  { id: 228, district_id: 20, name: "Bideha" },
  { id: 229, district_id: 20, name: "Mithila" },
  { id: 230, district_id: 20, name: "Sahidnagar" },
  { id: 231, district_id: 20, name: "Sabaila" },
  { id: 232, district_id: 20, name: "Kamala" },
  { id: 233, district_id: 20, name: "MithilaBihari" },
  { id: 234, district_id: 20, name: "Hansapur" },
  { id: 235, district_id: 20, name: "Janaknandani" },
  { id: 236, district_id: 20, name: "Bateshwar" },
  { id: 237, district_id: 20, name: "Mukhiyapatti Musharniya" },
  { id: 238, district_id: 20, name: "Lakshminya" },
  { id: 239, district_id: 20, name: "Aaurahi" },
  { id: 240, district_id: 20, name: "Dhanauji" },
  { id: 241, district_id: 21, name: "Bodebarsain" },
  { id: 242, district_id: 21, name: "Dakneshwori" },
  { id: 243, district_id: 21, name: "Hanumannagar Kankalini" },
  { id: 244, district_id: 21, name: "Kanchanrup" },
  { id: 245, district_id: 21, name: "Khadak" },
  { id: 246, district_id: 21, name: "Shambhunath" },
  { id: 247, district_id: 21, name: "Saptakoshi" },
  { id: 248, district_id: 21, name: "Surunga" },
  { id: 249, district_id: 21, name: "Rajbiraj" },
  { id: 250, district_id: 21, name: "Agnisaira Krishnasavaran" },
  { id: 251, district_id: 21, name: "Balan-Bihul" },
  { id: 252, district_id: 21, name: "Rajgadh" },
  { id: 253, district_id: 21, name: "Bishnupur" },
  { id: 254, district_id: 21, name: "Chhinnamasta" },
  { id: 255, district_id: 21, name: "Mahadeva" },
  { id: 256, district_id: 21, name: "Rupani" },
  { id: 257, district_id: 21, name: "Tilathi Koiladi" },
  { id: 258, district_id: 21, name: "Tirhut" },
  { id: 259, district_id: 22, name: "Aaurahi" },
  { id: 260, district_id: 22, name: "Balawa" },
  { id: 261, district_id: 22, name: "Bardibas" },
  { id: 262, district_id: 22, name: "Bhangaha" },
  { id: 263, district_id: 22, name: "Gaushala" },
  { id: 264, district_id: 22, name: "Jaleshor" },
  { id: 265, district_id: 22, name: "Loharpatti" },
  { id: 266, district_id: 22, name: "Manara Shiswa" },
  { id: 267, district_id: 22, name: "Matihani" },
  { id: 268, district_id: 22, name: "Ramgopalpur" },
  { id: 269, district_id: 22, name: "Ekdara" },
  { id: 270, district_id: 22, name: "Mahottari" },
  { id: 271, district_id: 22, name: "Pipara" },
  { id: 272, district_id: 22, name: "Samsi" },
  { id: 273, district_id: 22, name: "Sonama" },
  { id: 274, district_id: 23, name: "Bhaktapur" },
  { id: 275, district_id: 23, name: "Changunarayan" },
  { id: 276, district_id: 23, name: "Suryabinayak" },
  { id: 277, district_id: 23, name: "Madhyapur Thimi" },
  { id: 278, district_id: 24, name: "Bharatpur" },
  { id: 279, district_id: 24, name: "Kalika" },
  { id: 280, district_id: 24, name: "Khairhani" },
  { id: 281, district_id: 24, name: "Madi" },
  { id: 282, district_id: 24, name: "Ratnagar" },
  { id: 283, district_id: 24, name: "Rapti" },
  { id: 284, district_id: 24, name: "Ichchhakamana" },
  { id: 285, district_id: 25, name: "Dhunibeshi" },
  { id: 286, district_id: 25, name: "Nilkantha" },
  { id: 287, district_id: 25, name: "Khaniyabas" },
  { id: 288, district_id: 25, name: "Gajuri" },
  { id: 289, district_id: 25, name: "Galchhi" },
  { id: 290, district_id: 25, name: "Gangajamuna" },
  { id: 291, district_id: 25, name: "Jwalamukhi" },
  { id: 292, district_id: 25, name: "Thakre" },
  { id: 293, district_id: 25, name: "Netrawati Dabjong" },
  { id: 294, district_id: 25, name: "Benighat Rorang" },
  { id: 295, district_id: 25, name: "Rubi Valley" },
  { id: 296, district_id: 25, name: "Siddhalek" },
  { id: 297, district_id: 25, name: "Tripurasundari" },
  { id: 298, district_id: 26, name: "Bhimeswor" },
  { id: 299, district_id: 26, name: "Jiri" },
  { id: 300, district_id: 26, name: "Kalinchok" },
  { id: 301, district_id: 26, name: "Melung" },
  { id: 302, district_id: 26, name: "Bigu" },
  { id: 303, district_id: 26, name: "Gaurishankar" },
  { id: 304, district_id: 26, name: "Baiteshowr" },
  { id: 305, district_id: 26, name: "Sailung" },
  { id: 306, district_id: 26, name: "Tamakoshi" },
  { id: 307, district_id: 27, name: "Kathmandu" },
  { id: 308, district_id: 27, name: "Gokarneshwar" },
  { id: 309, district_id: 27, name: "Kirtipur" },
  { id: 310, district_id: 27, name: "Kageshwari-Manohara" },
  { id: 311, district_id: 27, name: "Chandragiri" },
  { id: 312, district_id: 27, name: "Tokha" },
  { id: 313, district_id: 27, name: "Tarakeshwar" },
  { id: 314, district_id: 27, name: "Dakshinkali" },
  { id: 315, district_id: 27, name: "Nagarjun" },
  { id: 316, district_id: 27, name: "Budhalikantha" },
  { id: 317, district_id: 27, name: "Shankharapur" },
  { id: 318, district_id: 28, name: "Dhulikhel" },
  { id: 319, district_id: 28, name: "Namobuddha" },
  { id: 320, district_id: 28, name: "Panauti" },
  { id: 321, district_id: 28, name: "Panchkhal" },
  { id: 322, district_id: 28, name: "Banepa" },
  { id: 323, district_id: 28, name: "Mandandeupur" },
  { id: 324, district_id: 28, name: "Khani Khola" },
  { id: 325, district_id: 28, name: "Chauri Deurali" },
  { id: 326, district_id: 28, name: "Temal" },
  { id: 327, district_id: 28, name: "Bethanchok" },
  { id: 328, district_id: 28, name: "Bhumlu" },
  { id: 329, district_id: 28, name: "Mahabharat" },
  { id: 330, district_id: 28, name: "Roshi" },
  { id: 331, district_id: 29, name: "Lalitpur" },
  { id: 332, district_id: 29, name: "Mahalaxmi" },
  { id: 333, district_id: 29, name: "Godawari" },
  { id: 334, district_id: 29, name: "Konjyosom" },
  { id: 335, district_id: 29, name: "Bagmati" },
  { id: 336, district_id: 29, name: "Mahankal" },
  { id: 337, district_id: 30, name: "Hetauda" },
  { id: 338, district_id: 30, name: "Thaha" },
  { id: 339, district_id: 30, name: "Bhimphedi" },
  { id: 340, district_id: 30, name: "Makawanpurgadhi" },
  { id: 341, district_id: 30, name: "Manahari" },
  { id: 342, district_id: 30, name: "Raksirang" },
  { id: 343, district_id: 30, name: "Bakaiya" },
  { id: 344, district_id: 30, name: "Bagmati" },
  { id: 345, district_id: 30, name: "Kailash" },
  { id: 346, district_id: 30, name: "Indrasarowar" },
  { id: 347, district_id: 31, name: "Bidur" },
  { id: 348, district_id: 31, name: "Belkotgadhi" },
  { id: 349, district_id: 31, name: "Kakani" },
  { id: 350, district_id: 31, name: "Panchakanya" },
  { id: 351, district_id: 31, name: "Likhu" },
  { id: 352, district_id: 31, name: "Dupcheshwar" },
  { id: 353, district_id: 31, name: "Shivapuri" },
  { id: 354, district_id: 31, name: "Tadi" },
  { id: 355, district_id: 31, name: "Suryagadhi" },
  { id: 356, district_id: 31, name: "Tarkeshwar" },
  { id: 357, district_id: 31, name: "Kispang" },
  { id: 358, district_id: 31, name: "Myagang" },
  { id: 359, district_id: 32, name: "Manthali" },
  { id: 360, district_id: 32, name: "Ramechhap" },
  { id: 361, district_id: 32, name: "Umakunda" },
  { id: 362, district_id: 32, name: "Khandadevi" },
  { id: 363, district_id: 32, name: "Doramba" },
  { id: 364, district_id: 32, name: "Gokulganga" },
  { id: 365, district_id: 32, name: "LikhuTamakoshi" },
  { id: 366, district_id: 32, name: "Sunapati" },
  { id: 367, district_id: 33, name: "Kalika" },
  { id: 368, district_id: 33, name: "Gosaikunda" },
  { id: 369, district_id: 33, name: "Naukunda" },
  { id: 370, district_id: 33, name: "Parbatikunda" },
  { id: 371, district_id: 33, name: "Uttargaya" },
  { id: 372, district_id: 34, name: "Kamalamai" },
  { id: 373, district_id: 34, name: "Dudhauli" },
  { id: 374, district_id: 34, name: "Sunkoshi" },
  { id: 375, district_id: 34, name: "Hariharpurgadhi" },
  { id: 376, district_id: 34, name: "Tinpatan" },
  { id: 377, district_id: 34, name: "Marin" },
  { id: 378, district_id: 34, name: "Golanjor" },
  { id: 379, district_id: 34, name: "Phikkal" },
  { id: 380, district_id: 34, name: "Ghyanglekh" },
  { id: 381, district_id: 35, name: "Chautara Sangachowkgadi" },
  { id: 382, district_id: 35, name: "Bahrabise" },
  { id: 383, district_id: 35, name: "Melamchi" },
  { id: 384, district_id: 35, name: "Balephi" },
  { id: 385, district_id: 35, name: "Sunkoshi" },
  { id: 386, district_id: 35, name: "Indrawati" },
  { id: 387, district_id: 35, name: "Jugal" },
  { id: 388, district_id: 35, name: "Panchpokhari" },
  { id: 389, district_id: 35, name: "Bhotekoshi" },
  { id: 390, district_id: 35, name: "Lisankhu" },
  { id: 391, district_id: 35, name: "Helambu" },
  { id: 392, district_id: 35, name: "Tripurasundari" },
  { id: 393, district_id: 36, name: "Baglung" },
  { id: 394, district_id: 36, name: "Dhorpatan" },
  { id: 395, district_id: 36, name: "Galkot" },
  { id: 396, district_id: 36, name: "Jaimuni" },
  { id: 397, district_id: 36, name: "Bareng" },
  { id: 398, district_id: 36, name: "Khathekhola" },
  { id: 399, district_id: 36, name: "Taman Khola" },
  { id: 400, district_id: 36, name: "Tara Khola" },
  { id: 401, district_id: 36, name: "Nishi Khola" },
  { id: 402, district_id: 36, name: "Badigad" },
  { id: 403, district_id: 37, name: "Gorkha" },
  { id: 404, district_id: 37, name: "Palungtar" },
  { id: 405, district_id: 37, name: "Sulikot" },
  { id: 406, district_id: 37, name: "Siranchowk" },
  { id: 407, district_id: 37, name: "Ajirkot" },
  { id: 408, district_id: 37, name: "Chumnubri" },
  { id: 409, district_id: 37, name: "Dharche" },
  { id: 410, district_id: 37, name: "Bhimsen Thapa" },
  { id: 411, district_id: 37, name: "Sahid Lakhan" },
  { id: 412, district_id: 37, name: "Aarughat" },
  { id: 413, district_id: 37, name: "Gandaki" },
  { id: 414, district_id: 38, name: "Pokhara" },
  { id: 415, district_id: 38, name: "Annapurna" },
  { id: 416, district_id: 38, name: "Machhapuchchhre" },
  { id: 417, district_id: 38, name: "Madi" },
  { id: 418, district_id: 38, name: "Rupa" },
  { id: 419, district_id: 39, name: "Besisahar" },
  { id: 420, district_id: 39, name: "Madhya Nepal" },
  { id: 421, district_id: 39, name: "Rainas" },
  { id: 422, district_id: 39, name: "Sundarbazar" },
  { id: 423, district_id: 39, name: "Dordi" },
  { id: 424, district_id: 39, name: "Dudhpokhari" },
  { id: 425, district_id: 39, name: "Kwhlosothar" },
  { id: 426, district_id: 39, name: "Marsyangdi" },
  { id: 427, district_id: 40, name: "Chame" },
  { id: 428, district_id: 40, name: "Nason" },
  { id: 429, district_id: 40, name: "NarpaBhumi" },
  { id: 430, district_id: 40, name: "Manang Ngisyang" },
  { id: 431, district_id: 41, name: "Gharpajhong" },
  { id: 432, district_id: 41, name: "Thasang" },
  { id: 433, district_id: 41, name: "Barhagaun Muktichhetra" },
  { id: 434, district_id: 41, name: "Lomanthang" },
  { id: 435, district_id: 41, name: "Lo-Ghekar Damodarkunda" },
  { id: 436, district_id: 42, name: "Beni" },
  { id: 437, district_id: 42, name: "Annapurna" },
  { id: 438, district_id: 42, name: "Dhaulagiri" },
  { id: 439, district_id: 42, name: "Mangala" },
  { id: 440, district_id: 42, name: "Malika" },
  { id: 441, district_id: 42, name: "Raghuganga" },
  { id: 442, district_id: 43, name: "Kawasoti" },
  { id: 443, district_id: 43, name: "Gaindakot" },
  { id: 444, district_id: 43, name: "Devachuli" },
  { id: 445, district_id: 43, name: "Madhya Bindu" },
  { id: 446, district_id: 43, name: "Baudikali" },
  { id: 447, district_id: 43, name: "Bulingtar" },
  { id: 448, district_id: 43, name: "Binayi Tribeni" },
  { id: 449, district_id: 43, name: "Hupsekot" },
  { id: 450, district_id: 44, name: "Kushma" },
  { id: 451, district_id: 44, name: "Phalewas" },
  { id: 452, district_id: 44, name: "Jaljala" },
  { id: 453, district_id: 44, name: "Paiyun" },
  { id: 454, district_id: 44, name: "Mahashila" },
  { id: 455, district_id: 44, name: "Modi" },
  { id: 456, district_id: 44, name: "Bihadi" },
  { id: 457, district_id: 45, name: "Galyang" },
  { id: 458, district_id: 45, name: "Chapakot" },
  { id: 459, district_id: 45, name: "Putalibazar" },
  { id: 460, district_id: 45, name: "Bheerkot" },
  { id: 461, district_id: 45, name: "Waling" },
  { id: 462, district_id: 45, name: "Arjun Chaupari" },
  { id: 463, district_id: 45, name: "Aandhikhola" },
  { id: 464, district_id: 45, name: "Kaligandaki" },
  { id: 465, district_id: 45, name: "Phedikhola" },
  { id: 466, district_id: 45, name: "Harinas" },
  { id: 467, district_id: 45, name: "Biruwa" },
  { id: 468, district_id: 46, name: "Bhanu" },
  { id: 469, district_id: 46, name: "Bhimad" },
  { id: 470, district_id: 46, name: "Byas" },
  { id: 471, district_id: 46, name: "Suklagandaki" },
  { id: 472, district_id: 46, name: "AnbuKhaireni" },
  { id: 473, district_id: 46, name: "Devghat" },
  { id: 474, district_id: 46, name: "Bandipur" },
  { id: 475, district_id: 46, name: "Rishing" },
  { id: 476, district_id: 46, name: "Ghiring" },
  { id: 477, district_id: 46, name: "Myagde" },
  { id: 478, district_id: 47, name: "Kapilvastu" },
  { id: 479, district_id: 47, name: "Banganga" },
  { id: 480, district_id: 47, name: "Buddhabhumi" },
  { id: 481, district_id: 47, name: "Shivaraj" },
  { id: 482, district_id: 47, name: "Krishnanagar" },
  { id: 483, district_id: 47, name: "Maharajgunj" },
  { id: 484, district_id: 47, name: "Mayadevi" },
  { id: 485, district_id: 47, name: "Yashodhara" },
  { id: 486, district_id: 47, name: "Suddhodan" },
  { id: 487, district_id: 47, name: "Bijaynagar" },
  { id: 488, district_id: 48, name: "Bardaghat" },
  { id: 489, district_id: 48, name: "Ramgram" },
  { id: 490, district_id: 48, name: "Sunwal" },
  { id: 491, district_id: 48, name: "Susta" },
  { id: 492, district_id: 48, name: "Palhi Nandan" },
  { id: 493, district_id: 48, name: "Pratappur" },
  { id: 494, district_id: 48, name: "Sarawal" },
  { id: 495, district_id: 49, name: "Butwal" },
  { id: 496, district_id: 49, name: "Devdaha" },
  { id: 497, district_id: 49, name: "Lumbini Sanskritik" },
  { id: 498, district_id: 49, name: "Sainamaina" },
  { id: 499, district_id: 49, name: "Siddharthanagar" },
  { id: 500, district_id: 49, name: "Tilottama" },
  { id: 501, district_id: 49, name: "Gaidahawa" },
  { id: 502, district_id: 49, name: "Kanchan" },
  { id: 503, district_id: 49, name: "Kotahimai" },
  { id: 504, district_id: 49, name: "Marchawari" },
  { id: 505, district_id: 49, name: "Mayadevi" },
  { id: 506, district_id: 49, name: "Omsatiya" },
  { id: 507, district_id: 49, name: "Rohini" },
  { id: 508, district_id: 49, name: "Sammarimai" },
  { id: 509, district_id: 49, name: "Siyari" },
  { id: 510, district_id: 49, name: "Suddodhan" },
  { id: 511, district_id: 50, name: "Sandhikharka" },
  { id: 512, district_id: 50, name: "Sitganga" },
  { id: 513, district_id: 50, name: "Bhumikasthan" },
  { id: 514, district_id: 50, name: "Chhatradev" },
  { id: 515, district_id: 50, name: "Panini" },
  { id: 516, district_id: 50, name: "Malarani" },
  { id: 517, district_id: 51, name: "Resunga" },
  { id: 518, district_id: 51, name: "Musikot" },
  { id: 519, district_id: 51, name: "Rurukshetra" },
  { id: 520, district_id: 51, name: "Chhatrakot" },
  { id: 521, district_id: 51, name: "Gulmidarbar" },
  { id: 522, district_id: 51, name: "Chandrakot" },
  { id: 523, district_id: 51, name: "Satyawati" },
  { id: 524, district_id: 51, name: "Dhurkot" },
  { id: 525, district_id: 51, name: "Kaligandaki" },
  { id: 526, district_id: 51, name: "Isma" },
  { id: 527, district_id: 51, name: "Malika" },
  { id: 528, district_id: 51, name: "Madane" },
  { id: 529, district_id: 52, name: "Tansen" },
  { id: 530, district_id: 52, name: "Rampur" },
  { id: 531, district_id: 52, name: "Rainadevi Chhahara" },
  { id: 532, district_id: 52, name: "Ripdikot" },
  { id: 533, district_id: 52, name: "Bagnaskali" },
  { id: 534, district_id: 52, name: "Rambha" },
  { id: 535, district_id: 52, name: "Purbakhola" },
  { id: 536, district_id: 52, name: "Nisdi" },
  { id: 537, district_id: 52, name: "Mathagadhi" },
  { id: 538, district_id: 52, name: "Tinahu" },
  { id: 539, district_id: 53, name: "Ghorahi" },
  { id: 540, district_id: 53, name: "Tulsipur" },
  { id: 541, district_id: 53, name: "Lamahi" },
  { id: 542, district_id: 53, name: "Gadhawa" },
  { id: 543, district_id: 53, name: "Rajpur" },
  { id: 544, district_id: 53, name: "Shantinagar" },
  { id: 545, district_id: 53, name: "Rapti" },
  { id: 546, district_id: 53, name: "Banglachuli" },
  { id: 547, district_id: 53, name: "Dangisharan" },
  { id: 548, district_id: 53, name: "Babai" },
  { id: 549, district_id: 54, name: "Sworgadwari" },
  { id: 550, district_id: 54, name: "Pyuthan" },
  { id: 551, district_id: 54, name: "Mandavi" },
  { id: 552, district_id: 54, name: "Sarumarani" },
  { id: 553, district_id: 54, name: "Ayirawati" },
  { id: 554, district_id: 54, name: "Mallarani" },
  { id: 555, district_id: 54, name: "Jhimruk" },
  { id: 556, district_id: 54, name: "Naubahini" },
  { id: 557, district_id: 54, name: "Gaumukhi" },
  { id: 558, district_id: 55, name: "Rolpa" },
  { id: 559, district_id: 55, name: "Runtigadi" },
  { id: 560, district_id: 55, name: "Triveni" },
  { id: 561, district_id: 55, name: "Sunil Smiriti" },
  { id: 562, district_id: 55, name: "Lungri" },
  { id: 563, district_id: 55, name: "Sunchhahari" },
  { id: 564, district_id: 55, name: "Thawang" },
  { id: 565, district_id: 55, name: "Madi" },
  { id: 566, district_id: 55, name: "GangaDev" },
  { id: 567, district_id: 55, name: "Pariwartan" },
  { id: 568, district_id: 56, name: "Putha Uttarganga" },
  { id: 569, district_id: 56, name: "Bhume" },
  { id: 570, district_id: 56, name: "Sisne" },
  { id: 571, district_id: 57, name: "Nepalgunj" },
  { id: 572, district_id: 57, name: "Kohalpur" },
  { id: 573, district_id: 57, name: "Rapti-Sonari" },
  { id: 574, district_id: 57, name: "Narainapur" },
  { id: 575, district_id: 57, name: "Duduwa" },
  { id: 576, district_id: 57, name: "Janaki" },
  { id: 577, district_id: 57, name: "Khajura" },
  { id: 578, district_id: 57, name: "Baijanath" },
  { id: 579, district_id: 58, name: "Gulariya" },
  { id: 580, district_id: 58, name: "Rajapur" },
  { id: 581, district_id: 58, name: "Madhuwan" },
  { id: 582, district_id: 58, name: "Thakurbaba" },
  { id: 583, district_id: 58, name: "Basgadhi" },
  { id: 584, district_id: 58, name: "Barbardiya" },
  { id: 585, district_id: 58, name: "Badhaiyatal" },
  { id: 586, district_id: 58, name: "Geruwa" },
  { id: 587, district_id: 59, name: "Aathabiskot" },
  { id: 588, district_id: 59, name: "Musikot" },
  { id: 589, district_id: 59, name: "Chaurjahari" },
  { id: 590, district_id: 59, name: "SaniBheri" },
  { id: 591, district_id: 59, name: "Triveni" },
  { id: 592, district_id: 59, name: "Banphikot" },
  { id: 593, district_id: 60, name: "Kumakh" },
  { id: 594, district_id: 60, name: "Kalimati" },
  { id: 595, district_id: 60, name: "Chhatreshwari" },
  { id: 596, district_id: 60, name: "Darma" },
  { id: 597, district_id: 60, name: "Kapurkot" },
  { id: 598, district_id: 60, name: "Triveni" },
  { id: 599, district_id: 60, name: "Siddha Kumakh" },
  { id: 600, district_id: 60, name: "Bagchaur" },
  { id: 601, district_id: 60, name: "Shaarda" },
  { id: 602, district_id: 60, name: "Bangad Kupinde" },
  { id: 603, district_id: 61, name: "Mudkechula" },
  { id: 604, district_id: 61, name: "Kaike" },
  { id: 605, district_id: 61, name: "She Phoksundo" },
  { id: 606, district_id: 61, name: "Jagadulla" },
  { id: 607, district_id: 61, name: "Dolpo Buddha" },
  { id: 608, district_id: 61, name: "Chharka Tongsong" },
  { id: 609, district_id: 61, name: "Thuli Bheri" },
  { id: 610, district_id: 61, name: "Tripurasundari" },
  { id: 611, district_id: 62, name: "Simkot" },
  { id: 612, district_id: 62, name: "Sarkegad" },
  { id: 613, district_id: 62, name: "Adanchuli" },
  { id: 614, district_id: 62, name: "Kharpunath" },
  { id: 615, district_id: 62, name: "Tanjakot" },
  { id: 616, district_id: 62, name: "Chankheli" },
  { id: 617, district_id: 62, name: "Namkha" },
  { id: 618, district_id: 63, name: "Tatopani" },
  { id: 619, district_id: 63, name: "Patarasi" },
  { id: 620, district_id: 63, name: "Tila" },
  { id: 621, district_id: 63, name: "Kanaka Sundari" },
  { id: 622, district_id: 63, name: "Sinja" },
  { id: 623, district_id: 63, name: "Hima" },
  { id: 624, district_id: 63, name: "Guthichaur" },
  { id: 625, district_id: 63, name: "Chandannath" },
  { id: 626, district_id: 64, name: "Khandachakra" },
  { id: 627, district_id: 64, name: "Raskot" },
  { id: 628, district_id: 64, name: "Tilagufa" },
  { id: 629, district_id: 64, name: "Narharinath" },
  { id: 630, district_id: 64, name: "Palata" },
  { id: 631, district_id: 64, name: "Shubha Kalika" },
  { id: 632, district_id: 64, name: "Sanni Triveni" },
  { id: 633, district_id: 64, name: "Pachaljharana" },
  { id: 634, district_id: 64, name: "Mahawai" },
  { id: 635, district_id: 65, name: "Khatyad" },
  { id: 636, district_id: 65, name: "Soru" },
  { id: 637, district_id: 65, name: "Mugum Karmarong" },
  { id: 638, district_id: 65, name: "Chhayanath Rara" },
  { id: 639, district_id: 66, name: "Simta" },
  { id: 640, district_id: 66, name: "Barahatal" },
  { id: 641, district_id: 66, name: "Chaukune" },
  { id: 642, district_id: 66, name: "Chingad" },
  { id: 643, district_id: 66, name: "Gurbhakot" },
  { id: 644, district_id: 66, name: "Birendranagar" },
  { id: 645, district_id: 66, name: "Bheriganga" },
  { id: 646, district_id: 66, name: "Panchapuri" },
  { id: 647, district_id: 66, name: "Lekbeshi" },
  { id: 648, district_id: 67, name: "Dullu" },
  { id: 649, district_id: 67, name: "Gurans" },
  { id: 650, district_id: 67, name: "Bhairabi" },
  { id: 651, district_id: 67, name: "Naumule" },
  { id: 652, district_id: 67, name: "Mahabu" },
  { id: 653, district_id: 67, name: "Thantikandh" },
  { id: 654, district_id: 67, name: "Bhagawatimai" },
  { id: 655, district_id: 67, name: "Dungeshwar" },
  { id: 656, district_id: 67, name: "Aathabis" },
  { id: 657, district_id: 67, name: "Narayan" },
  { id: 658, district_id: 67, name: "Chamunda Bindrasaini" },
  { id: 659, district_id: 68, name: "Chhedagad" },
  { id: 660, district_id: 68, name: "Bheri" },
  { id: 661, district_id: 68, name: "Nalgad" },
  { id: 662, district_id: 68, name: "Junichande" },
  { id: 663, district_id: 68, name: "Kuse" },
  { id: 664, district_id: 68, name: "Barekot" },
  { id: 665, district_id: 68, name: "Shivalaya" },
  { id: 666, district_id: 69, name: "Mahakali" },
  { id: 667, district_id: 69, name: "Shailyashikhar" },
  { id: 668, district_id: 69, name: "Naugad" },
  { id: 669, district_id: 69, name: "Malikarjun" },
  { id: 670, district_id: 69, name: "Marma" },
  { id: 671, district_id: 69, name: "Lekam" },
  { id: 672, district_id: 69, name: "Duhun" },
  { id: 673, district_id: 69, name: "Vyans" },
  { id: 674, district_id: 69, name: "Apihimal" },
  { id: 675, district_id: 70, name: "Jayaprithvi" },
  { id: 676, district_id: 70, name: "Bungal" },
  { id: 677, district_id: 70, name: "Kedarsyu" },
  { id: 678, district_id: 70, name: "Thalara" },
  { id: 679, district_id: 70, name: "Bitthadchir" },
  { id: 680, district_id: 70, name: "Chhabis Pathibhera" },
  { id: 681, district_id: 70, name: "Khaptadchhanna" },
  { id: 682, district_id: 70, name: "Masta" },
  { id: 683, district_id: 70, name: "Durgathali" },
  { id: 684, district_id: 70, name: "Talkot" },
  { id: 685, district_id: 70, name: "Surma" },
  { id: 686, district_id: 70, name: "Saipal" },
  { id: 687, district_id: 71, name: "Badimalika" },
  { id: 688, district_id: 71, name: "Triveni" },
  { id: 689, district_id: 71, name: "Budhiganga" },
  { id: 690, district_id: 71, name: "Budhinanda" },
  { id: 691, district_id: 71, name: "Khaptad Chhededaha" },
  { id: 692, district_id: 71, name: "Swami Kartik Khapar" },
  { id: 693, district_id: 71, name: "Jagannath" },
  { id: 694, district_id: 71, name: "Himali" },
  { id: 695, district_id: 71, name: "Gaumul" },
  { id: 696, district_id: 72, name: "Dashrathchanda" },
  { id: 697, district_id: 72, name: "Patan" },
  { id: 698, district_id: 72, name: "Melauli" },
  { id: 699, district_id: 72, name: "Purchaudi" },
  { id: 700, district_id: 72, name: "Dogdakedar" },
  { id: 701, district_id: 72, name: "Dilashaini" },
  { id: 702, district_id: 72, name: "Sigas" },
  { id: 703, district_id: 72, name: "Pancheshwar" },
  { id: 704, district_id: 72, name: "Surnaya" },
  { id: 705, district_id: 72, name: "Shivanath" },
  { id: 706, district_id: 73, name: "Dipayal Silgadhi" },
  { id: 707, district_id: 73, name: "Shikhar" },
  { id: 708, district_id: 73, name: "Aadarsha" },
  { id: 709, district_id: 73, name: "Purbichauki" },
  { id: 710, district_id: 73, name: "K.I.Singh" },
  { id: 711, district_id: 73, name: "Jorayal" },
  { id: 712, district_id: 73, name: "Sayal" },
  { id: 713, district_id: 73, name: "Bogatan-Phudsil" },
  { id: 714, district_id: 73, name: "Badikedar" },
  { id: 715, district_id: 74, name: "Ramaroshan" },
  { id: 716, district_id: 74, name: "Chaurpati" },
  { id: 717, district_id: 74, name: "Turmakhand" },
  { id: 718, district_id: 74, name: "Mellekh" },
  { id: 719, district_id: 74, name: "Dhakari" },
  { id: 720, district_id: 74, name: "Bannigadi Jayagad" },
  { id: 721, district_id: 74, name: "Mangalsen" },
  { id: 722, district_id: 74, name: "Kamalbazar" },
  { id: 723, district_id: 74, name: "Sanfebagar" },
  { id: 724, district_id: 74, name: "Panchadewal Binayak" },
  { id: 725, district_id: 75, name: "Navadurga" },
  { id: 726, district_id: 75, name: "Aalitaal" },
  { id: 727, district_id: 75, name: "Ganyapadhura" },
  { id: 728, district_id: 75, name: "Bhageshwar" },
  { id: 729, district_id: 75, name: "Ajaymeru" },
  { id: 730, district_id: 75, name: "Amargadhi" },
  { id: 731, district_id: 75, name: "Parshuram" },
  { id: 732, district_id: 76, name: "Bhimdatta" },
  { id: 733, district_id: 76, name: "Punarbas" },
  { id: 734, district_id: 76, name: "Bedkot" },
  { id: 735, district_id: 76, name: "Mahakali" },
  { id: 736, district_id: 76, name: "Shuklaphanta" },
  { id: 737, district_id: 76, name: "Belauri" },
  { id: 738, district_id: 76, name: "Krishnapur" },
  { id: 739, district_id: 76, name: "Laljhadi" },
  { id: 740, district_id: 76, name: "Beldandi" },
  { id: 741, district_id: 77, name: "Janaki" },
  { id: 742, district_id: 77, name: "Kailari" },
  { id: 743, district_id: 77, name: "Joshipur" },
  { id: 744, district_id: 77, name: "Bardagoriya" },
  { id: 745, district_id: 77, name: "Mohanyal" },
  { id: 746, district_id: 77, name: "Chure" },
  { id: 747, district_id: 77, name: "Tikapur" },
  { id: 748, district_id: 77, name: "Ghodaghodi" },
  { id: 749, district_id: 77, name: "Lamkichuha" },
  { id: 750, district_id: 77, name: "Bhajni" },
  { id: 751, district_id: 77, name: "Godawari" },
  { id: 752, district_id: 77, name: "Gauriganga" },
  { id: 753, district_id: 77, name: "Dhangadhi" },
];
