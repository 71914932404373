import { createApi } from "@reduxjs/toolkit/query/react";
import { updateToast } from "../redux/global-slice";
import { baseQueryWithAuth } from "../utils/utils";

export const gaurdianService = createApi({
  reducerPath: "gaurdianService",
  baseQuery: baseQueryWithAuth("gaurdian"),
  tagTypes: ["Guardian"],
  endpoints: (builder) => ({
    addGaurdian: builder.mutation({
      query: (body) => ({ url: "/", method: "POST", body }),
      invalidatesTags: ["Guardian"],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateToast({ type: 1, message: data.message }));
        } catch (_) {}
      },
    }),
    updateGaurdian: builder.mutation({
      query: (body) => ({ url: "/", method: "PUT", body }),
      invalidatesTags: ["Guardian"],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateToast({ type: 1, message: data.message }));
        } catch (_) {}
      },
    }),
    getGuardians: builder.query({
      query: () => "/",
      providesTags: ["Guardian"],
      transformResponse: (response) => response,
    }),
    getGaurdian: builder.query({
      query: (guardianId) => `/${guardianId}`,
      providesTags: ["Guardian"],
      transformResponse: (response) => response,
    }),
    deleteGaurdian: builder.mutation({
      query: (guardianId) => ({ url: `/${guardianId}`, method: "DELETE" }),
      invalidatesTags: ["Guardian"],
    }),
  }),
});

export const {
  useAddGaurdianMutation,
  useUpdateGaurdianMutation,
  useGetGuardiansQuery,
  useGetGaurdianQuery,
  useDeleteGaurdianMutation,
} = gaurdianService;
