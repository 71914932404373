import { createApi } from "@reduxjs/toolkit/query/react";
import { updateToast } from "../redux/global-slice";
import { baseQueryWithAuth } from "../utils/utils";

export const addressService = createApi({
  reducerPath: "addressService",
  baseQuery: baseQueryWithAuth("address"),
  tagTypes: ["Address"],
  endpoints: (builder) => ({
    addAddress: builder.mutation({
      invalidatesTags: ["Address"],
      query: (body) => ({ url: "/", method: "POST", body }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateToast({ type: 1, message: data.message }));
        } catch (_) {}
      },
    }),
    updateAddress: builder.mutation({
      invalidatesTags: ["Address"],
      query: (body) => ({ url: "/", method: "PUT", body }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateToast({ type: 1, message: data.message }));
        } catch (_) {}
      },
    }),
    getAddress: builder.mutation({
      providesTags: ["Address"],
      query: (userId) => ({ url: `/${userId}`, method: "GET" }),
      transformResponse: (response) => response,
    }),
    deleteAddress: builder.mutation({
      invalidatesTags: ["Address"],
      query: (userId) => ({ url: `/${userId}`, method: "DELETE" }),
    }),
  }),
});

export const {
  useAddAddressMutation,
  useUpdateAddressMutation,
  useGetAddressMutation,
  useDeleteAddressMutation,
} = addressService;
