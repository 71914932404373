import { getColors } from "../utils/utils";

const Tag = ({ text, color }) => {
  return (
    <div className="tag" style={{ background: color ?? getColors(text) }}>
      {text}
    </div>
  );
};

export default Tag;
