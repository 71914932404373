import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./redux/store";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.scss";
import "aos/dist/aos.css";
import AOS from "aos";
import {
  ROOT_PATH,
  USERS_PATH,
  SCHOOLS_PATH,
  LOGIN_PATH,
  COURSES_PATH,
  OFFERS_PATH,
  COURSE_TYPE_PATH,
} from "./utils/route-contants";
import Login from "./pages/login";
import App from "./App";
import Dashboard from "./pages/dashboard";
import Schools from "./pages/schools";
import PageNotFound from "./pages/page-not-found";
import Courses from "./pages/courses";
import Offers from "./pages/offers";
import CourseType from "./pages/course-type";
import Users from "./pages/users";

const router = createBrowserRouter([
  { path: LOGIN_PATH, element: <Login /> },
  {
    path: ROOT_PATH,
    element: <App />,
    children: [
      { path: "", index: true, element: <Dashboard /> },
      { path: USERS_PATH, element: <Users /> },
      { path: SCHOOLS_PATH, element: <Schools /> },
      { path: COURSES_PATH, element: <Courses /> },
      { path: OFFERS_PATH, element: <Offers /> },
      { path: COURSE_TYPE_PATH, element: <CourseType /> },
    ],
  },
  { path: "*", element: <PageNotFound /> },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
AOS.init();
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);
