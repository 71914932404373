import Header from "./components/app/header";
import Drawer from "./components/app/darwer";
import Footer from "./components/app/footer";
import { Navigate } from "react-router-dom";
import { LOGIN_PATH } from "./utils/route-contants";
import { Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  userReducer,
  loadingReducer,
  toastMessageReducer,
} from "./redux/reducers";
import Toast from "./components/toast";
import Loader from "./components/loader";

const App = () => {
  const user = useSelector(userReducer);
  const location = useLocation();
  const loading = useSelector(loadingReducer);
  const toast = useSelector(toastMessageReducer);

  if (!user && location.pathname !== LOGIN_PATH) {
    return <Navigate to={LOGIN_PATH} state={{ from: location }} replace />;
  }

  return (
    <div className="app">
      <Drawer />
      <section>
        <Header />
        <div className="body flex">
          <div className="innder__body">
            <Outlet />
          </div>
        </div>
        <Footer />
      </section>
      {toast && <Toast />}
      {loading && <Loader />}
    </div>
  );
};
export default App;
