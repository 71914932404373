import TableList from "../components/table_list";

const _headers = [
  { key: "name", title: "Name" },
  { key: "type", title: "Type" },
  { key: "category", title: "Category" },
  { key: "phone", title: "Phone" },
  { key: "email", title: "Email" },
  { key: "street", title: "Street" },
  { key: "muncipality", title: "Muncipality" },
  { key: "district", title: "District" },
  { key: "province", title: "Province" },
  { key: "year", title: "Established Year" },
  { key: "action", title: "Action" },
];

const Schools = () => {
  return (
    <TableList
      headers={_headers}
      loading={false}
      data={[]}
      error={null}
      isAdd={true}
    />
  );
};

export default Schools;
