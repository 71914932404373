import { configureStore } from "@reduxjs/toolkit";
import globalSlice from "./global-slice";
import authSlice from "./auth-slice";
import listsSlice from "./lists-slice";
import { apiMiddleware } from "../utils/api-middleware";
import { apiServicesList } from "../utils/constants";
import { listsService } from "../services/lists-service";
import { addressService } from "../services/address-service";
import { gaurdianService } from "../services/gaurdian-service";
import { userService } from "../services/user-service";
import { authService } from "../services/auth-service";

const store = configureStore({
  reducer: {
    global: globalSlice,
    auth: authSlice,
    lists: listsSlice,
    [listsService.reducerPath]: listsService.reducer,
    [authService.reducerPath]: authService.reducer,
    [addressService.reducerPath]: addressService.reducer,
    [gaurdianService.reducerPath]: gaurdianService.reducer,
    [userService.reducerPath]: userService.reducer,
  },
  middleware: (middleware) =>
    middleware({ serializableCheck: false }).concat(
      ...apiServicesList.map((service) => service.middleware),
      apiMiddleware
    ),
});

export default store;
