import { useState } from "react";
import ActionButtons from "./action-buttons";
import { relationList } from "../../utils/constants";

const GaurdianInfo = ({ data, view = true }) => {
  const [info, setInfo] = useState(
    data ?? {
      first_name: "",
      middle_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      relation: "",
    }
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInfo({ ...info, [name]: value });
  };

  return (
    <form className="flex flex-col gap4">
      <div
        className="form__group"
        onSubmit={(e) => {
          e.preventDefault();
          console.log(info);
        }}
      >
        <input
          type="text"
          placeholder="First Name*"
          value={info.first_name}
          name="first_name"
          required
          onChange={handleChange}
          disabled={view}
        />
        <input
          type="text"
          placeholder="Middle Name"
          value={info.middle_name}
          name="middle_name"
          onChange={handleChange}
          disabled={view}
        />
        <input
          type="text"
          placeholder="Last Name*"
          value={info.last_name}
          name="last_name"
          required
          onChange={handleChange}
          disabled={view}
        />
        <input
          type="text"
          placeholder="Email"
          value={info.email}
          name="email"
          onChange={handleChange}
          disabled={view}
        />
        <input
          type="text"
          placeholder="Phone*"
          value={info.phone_number}
          name="phone_number"
          required
          onChange={handleChange}
          disabled={view}
        />
        <select
          value={info.relation}
          name="relation"
          required
          onChange={handleChange}
          disabled={view}
        >
          <option>Select Relation</option>
          {relationList.map((x, i) => (
            <option key={i} value={x.key}>
              {x.title}
            </option>
          ))}
        </select>
      </div>
      {!view && (
        <ActionButtons
          cancel={() => console.log("calcel")}
          save={() => console.log("save")}
        />
      )}
    </form>
  );
};

export default GaurdianInfo;
