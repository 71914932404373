import TableList from "../components/table_list";
import { useGetCommentsQuery } from "../services/lists-service";

const _headers = [
  { key: "name", title: "Name" },
  { key: "email", title: "Email" },
  { key: "body", title: "Comment" },
];

const Dashboard = () => {
  const { isLoading, data, error } = useGetCommentsQuery();

  return (
    <TableList
      headers={_headers}
      loading={isLoading}
      data={data}
      error={error}
    />
  );
};

export default Dashboard;
