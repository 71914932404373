import { Plus } from "lucide-react";
import { useEffect, useMemo, useState } from "react";

const TableList = ({
  headers = [],
  loading = true,
  data = [],
  error = null,
  onClick = null,
}) => {
  const [search, setSearch] = useState("");
  const [list, setList] = useState([]);

  // Memoize the data to prevent unnecessary re-renders
  const memoizedData = useMemo(() => data, [data]);

  useEffect(() => {
    if (memoizedData.length > 0) {
      if (search.length > 0) {
        var res = memoizedData.filter((item) => {
          return Object.keys(item).some((key) => {
            return (
              item[key] &&
              item[key].toString().toLowerCase().includes(search.toLowerCase())
            );
          });
        });
        setList(res.filter((item) => item !== undefined));
      } else {
        setList(memoizedData);
      }
    }
  }, [search, memoizedData]); // Use memoizedData here

  return (
    <table>
      <thead>
        <tr>
          <th
            scope="col"
            colSpan={headers.length + 1}
            style={{ padding: "0", paddingBottom: "4px !important" }}
          >
            <div className="flex align-center justify-between">
              <div style={{ maxWidth: "500px" }}>
                <input
                  type="text"
                  placeholder="Search..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              {onClick && (
                <button onClick={onClick}>
                  <Plus size={16} />
                  <span>ADD</span>
                </button>
              )}
            </div>
          </th>
        </tr>
        <tr>
          <th scope="col">S.N.</th>
          {headers.map((item) => (
            <th scope="col" key={item["key"]}>
              {item["title"]}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <tr>
            <td colSpan={headers.length + 1}>
              <div className="flex align-center justify-center">
                <div className="loader" />
              </div>
            </td>
          </tr>
        ) : error ? (
          <tr>
            <td colSpan={headers.length + 1}>
              <div className="flex align-center justify-center">
                Something went wrong while fetching the data. Please try again!
              </div>
            </td>
          </tr>
        ) : list.length > 0 ? (
          list.map((item, i) => (
            <tr key={i}>
              <td>{i + 1}.</td>
              {headers.map((head) => (
                <td key={head["key"]}>{item[head["key"]]}</td>
              ))}
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={headers.length + 1} style={{ textAlign: "center" }}>
              No Data Found
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
export default TableList;
