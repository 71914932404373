import { createSlice } from "@reduxjs/toolkit";
import { listsService } from "../services/lists-service";

const listsSlice = createSlice({
  name: "lists",
  initialState: {
    posts: [],
    comments: [],
    albums: [],
    photos: [],
    todos: [],
    users: [],
    courseTypes: [],
    courses: [],
  },
  reducers: {
    updateCourseTypes: (state, payload) => {
      var x = [...state.courseTypes];
      const { name, code, description } = payload.payload;
      x.push({
        name: name,
        code: code,
        description: description,
      });
      state.courseTypes = x;
    },
    updateCourses: (state, payload) => {
      var x = [...state.courses];
      const { name, duration, type, start_date, end_date, category } =
        payload.payload;
      x.push({
        name: name,
        duration: duration,
        type: type,
        start_date: start_date,
        end_date: end_date,
        category: category,
      });
      state.courses = x;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        listsService.endpoints.getPosts.matchFulfilled,
        (state, action) => {
          state.posts = action.payload;
        }
      )
      .addMatcher(
        listsService.endpoints.getComments.matchFulfilled,
        (state, action) => {
          state.comments = action.payload;
        }
      )
      .addMatcher(
        listsService.endpoints.getAlbums.matchFulfilled,
        (state, action) => {
          state.albums = action.payload;
        }
      )
      .addMatcher(
        listsService.endpoints.getPhotos.matchFulfilled,
        (state, action) => {
          state.photos = action.payload;
        }
      )
      .addMatcher(
        listsService.endpoints.getTodos.matchFulfilled,
        (state, action) => {
          state.todos = action.payload;
        }
      )
      .addMatcher(
        listsService.endpoints.getUsers.matchFulfilled,
        (state, action) => {
          state.users = action.payload;
        }
      );
  },
});

// Add a thunk to fetch posts on registration
// export const fetchPostsOnRegistration = () => async (dispatch) => {
//   try {
//     // Dispatch the getPosts query from postsService
//     const response = await listsService.endpoints.getPosts.initiate();
//     // Wait for the query to complete
//     await response;
//   } catch (error) {
//     console.error("Error fetching posts:", error);
//   }
// };
// // Dispatch the thunk to fetch posts after successful registration
// dispatch(fetchPostsOnRegistration());

export const { updateCourseTypes, updateCourses } = listsSlice.actions;
export default listsSlice.reducer;
