import TableList from "../components/table_list";

const _headers = [
  { key: "code", title: "Code" },
  { key: "is_percentage", title: "Is Percentage?" },
  { key: "amount", title: "Amount" },
  { key: "start_date", title: "Start Date" },
  { key: "end_date", title: "End Date" },
  { key: "times", title: "Times" },
  { key: "for_course", title: "For Course" },
];

const Offers = () => {
  return (
    <TableList
      headers={_headers}
      loading={false}
      data={[]}
      error={null}
      isAdd={true}
    />
  );
};

export default Offers;
