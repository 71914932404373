export const postsReducer = (state) => state.lists.posts;
export const commentsReducer = (state) => state.lists.comments;
export const albumsReducer = (state) => state.lists.posts;
export const photosReducer = (state) => state.lists.posts;
export const todosReducer = (state) => state.lists.posts;
export const usersReducer = (state) => state.lists.posts;
export const postsErrorReducer = (state) => state.lists.error;
export const courseTypesReducer = (state) => state.lists.courseTypes;
export const coursesReducer = (state) => state.lists.courses;
export const userReducer = (state) => state.auth.user;
export const loadingReducer = (state) => state.global.loading;
export const toastMessageReducer = (state) => state.global.toastMessage;
export const toastTypeReducer = (state) => state.global.toastType;
