import { isAnyOf } from "@reduxjs/toolkit";
import { apiServicesList } from "./constants";
import { updateLoading, updateToast } from "../redux/global-slice";
import { logout } from "../redux/auth-slice";

// Initialize actions outside the middleware for efficiency
const pendingActions = apiServicesList.flatMap((service) =>
  Object.values(service.endpoints).map((endpoint) => endpoint.matchPending)
);
const fulfilledActions = apiServicesList.flatMap((service) =>
  Object.values(service.endpoints).map((endpoint) => endpoint.matchFulfilled)
);
const rejectedActions = apiServicesList.flatMap((service) =>
  Object.values(service.endpoints).map((endpoint) => endpoint.matchRejected)
);

export const apiMiddleware = (api) => (next) => (action) => {
  if (isAnyOf(...pendingActions)(action)) {
    api.dispatch(updateLoading(true));
  } else if (isAnyOf(...fulfilledActions, ...rejectedActions)(action)) {
    api.dispatch(updateLoading(false));
  }

  if (isAnyOf(...rejectedActions)(action)) {
    const error =
      action.payload?.data?.message || action.error?.message || "An error occurred.";
    api.dispatch(updateToast({ message: error, type: 0 }));
  }

  // General status code handling, especially for unauthorized access
  const statusCode = action.payload?.status;
  if (statusCode === 401) {
    api.dispatch(logout());
  }

  // Debug logging, disabled in production
  if (process.env.NODE_ENV !== "production") {
    console.log("API Middleware Action:", action);
  }

  return next(action);
};
