import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const baseQueryWithAuth = (path) =>
  fetchBaseQuery({
    baseUrl: `${
      // process.env.DEV_TYPE === "DEV"
      //   ? process.env.REACT_APP_API_DEV_URL
      //   : process.env.REACT_APP_API_PROD_URL
      'http://localhost:3000'
    }/${path}`,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  });

export const getColors = (val) => {
  switch (val) {
    case "male":
      return "blue";
    case "female":
      return "red";
    case "student":
      return "green";
    case "teacher":
      return "purple";
    case "accountant":
      return "teal";
    case "admin":
      return "maroon";
    case "super_admin":
      return "orange";
    default:
      return "var(--primary-color)";
  }
};
