import { useEffect, useState } from "react";
import {
  allDistrictsList,
  allMuncipalsList,
  allProvidencesList,
} from "../../utils/addresses";
import ActionButtons from "./action-buttons";
import {useAddAddressMutation} from "../../services/address-service";

// address_type: temporary, permanent
const AddressInfo = ({ type = "permanent", data, view = true }) => {
  const [info, setInfo] = useState({
    country: "Nepal",
    providence: "",
    district: "",
    muncipality: "",
    street: "",
    address_type: type,
  });

  const [districtList, setdistrictList] = useState([]);
  const [muncipalList, setmuncipalList] = useState([]);
  const [addAddress] = useAddAddressMutation();

  useEffect(() => {
    if (data) {
      var province = allProvidencesList.find((e) => e.name === data.providence);
      var district = allDistrictsList
        .filter((e) => e.province_id === province.id)
        .map((e) => e.name);
      var muncipal = allMuncipalsList
        .filter((e) => e.district_id === district.id)
        .map((e) => e.name);
      setdistrictList(district);
      setmuncipalList(muncipal);
      setInfo(data);
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (["providence", "district", "muncipal"].includes(name)) {
      if (name === "providence") {
        var y = allProvidencesList.find((e) => e.name === value);
        var districts = allDistrictsList.filter((e) => e.province_id === y.id);
        setdistrictList(districts.map((e) => e.name));
        setmuncipalList([]);
        setInfo({ ...info, district: "", muncipality: "" });
      }
      if (name === "district") {
        var x = allDistrictsList.find((e) => e.name === value);
        var muncipalities = allMuncipalsList.filter(
          (e) => e.district_id === x.id
        );
        setmuncipalList(muncipalities.map((e) => e.name));
        setInfo({ ...info, muncipality: "" });
      }
    }
    setInfo({ ...info, [name]: value });
  };

  return (
    <form
      className="flex flex-col gap4"
      onSubmit={(e) => {
        e.preventDefault();
        console.log(info);
      }}
    >
      <div className="form__group">
        <select
          placeholder="Country"
          value={info.country}
          name="country"
          onChange={handleChange}
          disabled={view}
        >
          <option value="Nepal">Nepal</option>
        </select>
        <select
          value={info.providence}
          name="providence"
          onChange={handleChange}
          disabled={view}
        >
          <option>Select Province</option>
          {allProvidencesList
            .map((e) => e.name)
            .map((p, i) => (
              <option key={i} value={p}>
                {p}
              </option>
            ))}
        </select>
        <select
          placeholder="District"
          value={info.district}
          name="district"
          disabled={view || info.providence === ""}
          onChange={handleChange}
        >
          <option>Select District</option>
          {districtList.map((p, i) => (
            <option key={i} value={p}>
              {p}
            </option>
          ))}
        </select>
        <select
          placeholder="Muncipality"
          value={info.muncipality}
          name="muncipality"
          disabled={view || info.district === ""}
          onChange={handleChange}
        >
          <option>Select Muncipal</option>
          {muncipalList.map((p, i) => (
            <option key={i} value={p}>
              {p}
            </option>
          ))}
        </select>
      </div>
      <input
          type="text"
          placeholder="Street..."
          value={info.street}
          name="street"
          required
          disabled={view}
          onChange={handleChange}
        />
      {!view && (
        <ActionButtons
          cancel={() => console.log("calcel")}
          save={() => console.log("save")}
        />
      )}
    </form>
  );
};

export default AddressInfo;
