import { createApi } from "@reduxjs/toolkit/query/react";
import { updateToast } from "../redux/global-slice";
import { baseQueryWithAuth } from "../utils/utils";

export const userService = createApi({
  reducerPath: "userService",
  baseQuery: baseQueryWithAuth("users"),
  tagTypes: ["User"],
  endpoints: (builder) => ({
    getAllUsers: builder.query({
      query: () => "/",
      providesTags: ["User"],
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response,
    }),
    getSingleUser: builder.query({
      query: (userId) => `/${userId}`,
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response,
      providesTags: (result, error, userId) => [{ type: "User", id: userId }],
    }),
    updateUser: builder.mutation({
      query: (body) => ({ url: "/", method: "PUT", body }),
      invalidatesTags: ["User"],
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          console.log(data);
          dispatch(
            updateToast({ message: "User updated successfully!", type: 1 })
          );
        } catch (_) {}
      },
    }),
    deleteUser: builder.mutation({
      query: (userId) => ({ url: `/${userId}`, method: "DELETE" }),
      invalidatesTags: ["User"],
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          console.log(data);
          dispatch(
            updateToast({ message: "User deleted successfully!", type: 1 })
          );
        } catch (_) {}
      },
    }),
  }),
});

export const {
  useGetAllUsersQuery,
  useGetSingleUserQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = userService;
