import { useState } from "react";
import ActionButtons from "./action-buttons";
import { getGenderList, getRolesList } from "../../utils/constants";
import useUserInfo from "../../hooks/use-user-info-hook";
import { useNewUserMutation } from "../../services/auth-service";
import { useUpdateUserMutation } from "../../services/user-service";

/* basic info: first name, middle name, last name, email, phone, gender, dob, about, photo */
const BasicInfo = ({
  selected,
  setSelected,
  reset = () => {},
  view = true,
}) => {
  const { role } = useUserInfo();
  const [newUser] = useNewUserMutation();
  const [updateUser] = useUpdateUserMutation();
  const [info, setInfo] = useState(
    selected ?? {
      first_name: "",
      middle_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      gender: "male",
      date_of_birth: "",
      password: "",
      role: "student",
      username: "",
    }
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInfo({ ...info, [name]: value });
  };

  return (
    <form
      className="flex flex-col gap4"
      onSubmit={async (e) => {
        e.preventDefault();
        if (!view) {
          var res = selected ? await updateUser(info) : await newUser(info);
          if (res && res.id) {
            if (selected) {
              setSelected({
                ...selected,
                basic_info: info,
              });
            } else {
              setSelected({
                basic_info: info,
                permanent_address: null,
                temporary_address: null,
                guardian_info: null,
              });
            }
          }
        }
      }}
    >
      <div className="form__group">
        <select
          value={info.role}
          name="role"
          required
          onChange={handleChange}
          disabled={view}
        >
          {getRolesList(role).map((x, i) => (
            <option key={i} value={x.key}>
              {x.title}
            </option>
          ))}
        </select>
        <input
          type="text"
          placeholder="First Name*"
          value={info.first_name}
          name="first_name"
          required
          disabled={view}
          onChange={handleChange}
        />
        <input
          type="text"
          placeholder="Middle Name"
          value={info.middle_name}
          name="middle_name"
          onChange={handleChange}
          disabled={view}
        />
        <input
          type="text"
          placeholder="Last Name*"
          required
          value={info.last_name}
          name="last_name"
          onChange={handleChange}
          disabled={view}
        />
        <input
          type="text"
          placeholder="Email"
          value={info.email}
          name="email"
          onChange={handleChange}
          disabled={view}
        />
        <input
          type="text"
          placeholder="Phone*"
          value={info.phone_number}
          name="phone_number"
          required
          onChange={handleChange}
          disabled={view}
        />
        <input
          type="text"
          placeholder="Username"
          value={info.username}
          name="username"
          onChange={handleChange}
          disabled={view}
        />
        <select
          value={info.gender}
          name="gender"
          required
          disabled={view}
          onChange={handleChange}
        >
          {getGenderList.map((x, i) => (
            <option key={i} value={x.key}>
              {x.title}
            </option>
          ))}
        </select>
        <input
          type="date"
          placeholder="DOB*"
          value={info.date_of_birth}
          name="date_of_birth"
          required
          onChange={handleChange}
          max={new Date(2012, 0, 1).toISOString().split("T")[0]}
          disabled={view}
        />
        {!view && !selected && (
          <input
            type="password"
            placeholder="Password*"
            value={info.password}
            name="password"
            required
            onChange={handleChange}
          />
        )}
      </div>
      {!view && (
        <ActionButtons title={selected ? "Update" : "Save"} cancel={reset} />
      )}
    </form>
  );
};

export default BasicInfo;
