const ActionButtons = ({ title = "Save", cancel }) => {
  return (
    <div className="flex gap2 justify-end">
      <button type="submit">{title}</button>
      <button type="reset" className="danger" onClick={cancel}>
        Cancel
      </button>
    </div>
  );
};

export default ActionButtons;
