import { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import {
  loadingReducer,
  toastMessageReducer,
  userReducer,
} from "../redux/reducers";
import { useLoginUserMutation } from "../services/auth-service";
import Toast from "../components/toast";
import Loader from "../components/loader";

const Login = () => {
  const isAuthenticated = useSelector(userReducer);
  const [info, setInfo] = useState({
    username: "",
    password: "",
  });
  const location = useLocation();
  const loading = useSelector(loadingReducer);
  const toast = useSelector(toastMessageReducer);
  const [loginUser] = useLoginUserMutation();

  const handleSubmit = (e) => {
    e.preventDefault();
    loginUser(info);
  };

  if (isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return (
    <div className="login p4 flex align-center justify-center">
      <div className="login__container p8 flex flex-col justify-center gap3">
        <h2>Login</h2>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col justify-center gap3"
        >
          <input
            type="text"
            placeholder="Username, Email or Phone Number"
            value={info.username}
            required
            onChange={(e) => setInfo({ ...info, username: e.target.value })}
          />
          <input
            type="password"
            placeholder="Password"
            value={info.password}
            required
            onChange={(e) => setInfo({ ...info, password: e.target.value })}
          />
          <button type="submit">Submit</button>
        </form>
      </div>
      {toast && <Toast />}
      {loading && <Loader />}
    </div>
  );
};

export default Login;
