import { useEffect, useRef, useState } from "react";
import { Edit, Eye, MoreVertical, Trash2 } from "lucide-react";

const ActionMenu = ({ view, edit, deleteFun }) => {
  const menuRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="action__menu" ref={menuRef}>
      <button className="icon small" onClick={() => setIsOpen(!isOpen)}>
        <MoreVertical size={14} />
      </button>
      {isOpen && (
        <div className="action__menu__dropdown">
          {view && (
            <button
              className="small link"
              onClick={view}
              style={{ color: "green" }}
            >
              <Eye />
              <span>View</span>
            </button>
          )}
          {edit && (
            <button className="small link" onClick={edit}>
              <Edit />
              <span>Edit</span>
            </button>
          )}
          {deleteFun && (
            <button
              className="small link"
              onClick={deleteFun}
              style={{ color: "red" }}
            >
              <Trash2 />
              <span>Delete</span>
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default ActionMenu;
